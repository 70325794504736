<template>
  <r-norm-step :analysisObj="analysisObj">
  </r-norm-step>
</template>

<script>
import { workflow } from '@/mixins/workflow'
import { useWorkspaceStore } from '@/stores/workspacestore'

export default {
  name: 'RNormalisation',
  components: {
    'r-norm-step': () => import('@/components/ranalyses/RNormalizationStep.vue')
  },
  mixins: [workflow],
  props: {
    analysisObj: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    return { workspaceStore }
  },
  mounted () {
    if (!this.workspaceStore.hasDataset) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.workspaceStore.getWorkflow()
        .then(() => {
          loadingComponent.close()
        })
        .catch(() => {
          loadingComponent.close()
        })
    }
  }
}
</script>
